.access-code-input {
  color: black;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: none;
  text-align: center;
  font-size: 14px;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: transparent;
  }

  &__border {
    position: absolute;
    height: 3px;
    width: 0%;
    bottom: 0;
    left: 50%;
    background-color: transparent;
    transition: width 200ms, left 200ms;

    &-fullfiled {
      background-color: blue;
      animation: ExpendBorderBottom 200ms;
      animation-fill-mode: forwards;
    }

    &-active {
      animation: ExpendBorderBottom 200ms;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes ExpendBorderBottom {
  from {
    width: 0;
    left: 50%;
  }
  to {
    width: 100%;
    left: 0%;
  }
}

.access-code-form .submit-access-code-btn.loading-btn {
  padding: 0.5px 20px;
}
