body {
  margin: 0;
  font-family: "Libre Franklin", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p.semibold,
s.semibold,
h5.semibold,
h6.semibold {
  font-weight: 600;
}

button.actionBtn,
a.actionBtn {
  width: 100%;
  padding: 1em;
  color: #fff;
}

.overflowText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.withHorizontalScrollbar {
  overflow-y: auto;
  height: calc(
    100vh - 70px
  ); // 70px is the height of the NavBar section, it shouldn't be scrollable, only the rest of the page
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar {
  width: 8px !important;
  background: transparent !important;
  height: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #fafafa !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px !important;
  background: #003366 !important;
}
